import { MediaMatcher } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { AppService } from '../services/app.service';
import { Observable, Subject } from 'rxjs';
import { PopupComponent } from '../popup/popup.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { SharedService } from '../services/shared.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {

  public res_apps;

  ngOnInit() {
    console.log(this.AppService.breadcrumb);
    this.AppService.breadcrumb.splice(1,2);
    this.getApps();
  }


  getApps(): void {
    this.NgxSpinnerService.show();
    this.AppService.getApps().subscribe(
      data => { 
        this.res_apps = data.apps;
        this.NgxSpinnerService.hide();
       }
    );
  }

  editApp(data): void {
    this.NgxSpinnerService.show();
    // console.log(data);
    this.AppService.editApp(data).subscribe(
      data => {
        this.NgxSpinnerService.hide();
        this.res_apps = data;
        alert(data.message);
      }
    );
  }

  deleteApp(data): void {
    this.NgxSpinnerService.show();
    this.AppService.deleteApp(data).subscribe(
      data => {
        this.NgxSpinnerService.hide();
        this.res_apps = data;
        alert(data.message);
      }
    );
  }

  editDialog(app): void {
    let dialogRef = this.dialog.open(PopupComponent, {
      width: '250px',
      data: { "title": "App", "appid": app.appid, "name": app.appname, "text": "App Name", "type": "edit", "btnText": 'Edit' }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (result != undefined) {
        this.editApp(result);
        this.getApps();
      }
    });
  }

  deleteDialog(app): void {
    let dialogRef = this.dialog.open(PopupComponent, {
      width: '250px',
      data: { title: "Category", "name": app.appname, text: "Delete this App", type: "delete", btnText: 'Delete' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        this.deleteApp(result);
      }
    });
  }
  
  


  constructor(private AppService: AppService, public dialog: MatDialog, private route: ActivatedRoute,private titleService: Title,private SharedService:SharedService,private NgxSpinnerService:NgxSpinnerService) { }

}
