import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders, HttpResponse, HttpRequest } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpModule, Http, URLSearchParams, Headers, RequestOptions } from '@angular/http';
import { environment } from '../../environments/environment';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Injectable()
export class SharedService {

  public getReferencesUrl = environment.baseUrl + 'references';
  // auth Urls
  public getLogginUrl = environment.baseUrl + 'authorlogin';
  public registerUrl = environment.baseUrl + 'registerauthor';
  // app service URLs
  public getAppsUrl = environment.baseUrl + 'apps';
  public appsActionUrl = environment.baseUrl + 'app';
  // Category service URLs
  public getUrl = environment.baseUrl + 'appcategories/';
  public appcategoryUrl = environment.baseUrl + 'appcategory';
  public appcategorySortUrl = environment.baseUrl + 'sortcategories';
  public getUrlKb = environment.baseUrl + 'kbcategories';
  // KBHTML service URLs
  public kbHtmlActionUrl = environment.baseUrl + 'kbhtml';
  public kbHtmlAllUrl = environment.baseUrl + 'kbhtmls/';
  public kbHtmlSortUrl = environment.baseUrl + 'sortkbhtml';
  // Question Service URLs
  public getQuestionsInTopicUrl = environment.baseUrl + 'appquestionsintopic/';
  public getQuestionsNotInTopicUrl = environment.baseUrl + 'appquestionsnotintopic/';
  public questionsActionUrl = environment.baseUrl + 'apptopicquestions/';
  public kbQuestionsActionUrl = environment.baseUrl + 'kbquestion';
  public kbQuestionsAllUrl = environment.baseUrl + 'kbquestions';
  public getQuestionsTypeUrl = environment.baseUrl + 'questiontypes';
  public addQueToTopicUrl = environment.baseUrl + "apptopicquestions";
  public deleteQuestionFromTopicUrl = environment.baseUrl + "apptopicquestions";
  // Topic Service URLs
  public kbTopicUrl = environment.baseUrl + 'kbtopics/';
  public kbTopicActionUrl = environment.baseUrl + 'kbtopic';
  public getTopicUrl = environment.baseUrl + 'apptopics/';
  public topicActionUrl = environment.baseUrl + 'apptopic';
  public apptopictypesUrl = environment.baseUrl + 'apptopictypes';
  public getTopicLevelUrl = environment.baseUrl + 'levels';
  public appTopicSortUrl = environment.baseUrl + 'sorttopics';
  public appKbTopicSortUrl = environment.baseUrl + 'sortkbtopics';
  public iconUploadURL = environment.baseUrl+'icon';
  public iconGetURL = environment.iconGetBaseURL + "get_image.php?id=";

 // public iconUploadURL = 'https://api.codeadda.com/v1admin/icon/';

  public uploadIconId = 0;
  public previewUrl = this.iconGetURL + "0";
  public appid = 0;

  constructor(
    private http: HttpClient) {
  }

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '5rem',
    minHeight: '5rem',
    // width: '50%',
    placeholder: 'Enter text here...',
    translate: 'no',
    uploadUrl: this.iconUploadURL, // if needed
    customClasses: [ // optional
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };

  showPreviewImage(event: any) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.previewUrl = event.target.result;
      }
      reader.readAsDataURL(event.target.files[0]);
    }
  }

}
