import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { PopupComponent } from '../popup/popup.component';
import { QuestionsService } from '../services/questions.service';
import { kbhtmlService } from '../services/kbhtml.service';
import { AppService } from '../services/app.service';
import { CategorypopupComponent } from '../categorypopup/categorypopup.component';
import { SharedService } from '../services/shared.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { SortablejsOptions } from 'angular-sortablejs/dist';

@Component({
  selector: 'app-kbhtml',
  templateUrl: './kbhtml.component.html',
  styleUrls: ['./kbhtml.component.css']
})
export class kbhtmlComponent {

  constructor(private fb: FormBuilder, public dialog: MatDialog, private QuestionsService: QuestionsService, private route: ActivatedRoute, private kbhtmlService: kbhtmlService, private AppService: AppService, private SharedService: SharedService, private NgxSpinnerService: NgxSpinnerService) { }
  displayedColumns = ['name', 'weight'];
  dataSource;
  topicid = 0;
  originalData = [];
  res_apps = 0;

  ngOnInit() {
    this.getKbHtmls(this.topicid);
    this.AppService.breadcrumb[1] = { name: "KB HTML", url: "kbhtml/" };
    this.AppService.breadcrumb.splice(2, 1);
  }

  options: SortablejsOptions = {
    onEnd: (event: any) => {
      if (event.oldIndex != event.newIndex) {
        console.log(this.originalData);
        this.sortKbTopics(this.originalData);
      }
    },
    "disabled": true,
  };

  enableSort() {
    this.options = {
      "disabled": !this.options.disabled,
    }
  }

  sortKbTopics(data): void {
    this.kbhtmlService.sortKbHtml(data).subscribe(
      data => { this.res_apps = data }
    );
  }

  getKbHtmls(topicid): void {
    this.NgxSpinnerService.show();
    this.kbhtmlService.getKbHtmls(topicid).subscribe(
      data => {
        this.dataSource = data.kbhtmls;
        data.kbhtmls.forEach((element, index) => {
          this.originalData.push(element.kbhtmlid);
        });
        this.NgxSpinnerService.hide();
      }
    );
  }

  deleteDialog(element): void {
    let dialogRef = this.dialog.open(CategorypopupComponent, {
      width: '250px',
      data: { title: "KB HTML", name: "", text: "Delete this HTML", type: "delete", "btnText": 'Delete', kbhtmlid: element.kbhtmlid }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.deleteKbHtml(result);
    });
  }

  deleteKbHtml(data): void {
    this.NgxSpinnerService.show();
    this.kbhtmlService.deleteKbHtml(data).subscribe(
      data => {
        this.NgxSpinnerService.hide();
        alert(data.message);
      }
    );
    this.getKbHtmls(this.topicid);
  }

}

