import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {PopupComponent} from '../popup/popup.component';


@Component({
  selector: 'app-addapp',
  templateUrl:'./addapp.component.html',
  styleUrls: ['./addapp.component.css']
})
export class AddappComponent implements OnInit {
  addappForm: FormGroup;
  private formSubmitAttempt: boolean;
  isLinear = true;
  title: string;
  name: string;
  text: string;

  constructor(private fb: FormBuilder,public dialog: MatDialog) { }

  ngOnInit() {
    this.addappForm = this.fb.group({
      appName: ['', Validators.required],
      level: ['', Validators.required],
      category: ['', Validators.required],
      subCategory: ['', Validators.required],
      topics: ['', Validators.required],
      questionText: ['', Validators.required],
      questionExplainText: ['', Validators.required],
      questionType: ['', Validators.required],
      questions: ['', Validators.required],
    });
    
  }
  topicDialog(): void {
    let dialogRef = this.dialog.open(PopupComponent, {
      width: '250px',
      data: { title: "Topic", name: "", text: "Topic" }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.title = result;
    });
  }

  subCategoryDialog(): void {
    let dialogRef = this.dialog.open(PopupComponent, {
      width: '250px',
      data: { title: "SubCategory", name: "", text: "SubCategory" }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.title = result;
    });
  }

}

