import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { User } from './user';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders, HttpResponse, HttpRequest } from '@angular/common/http';
import { SharedService } from '../services/shared.service';

let headerOptions = { 'Content-Type': 'application/json', 'Authorization': '' };
const httpOptions = {
  headers: new HttpHeaders(headerOptions)
};

@Injectable()
export class AuthService {
  private loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private getLogginUrl = this.SharedService.getLogginUrl;  // URL to web api
  private registerUrl = this.SharedService.registerUrl;  // URL to web api

  loggedUser;
  logginResp;
  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  constructor(
    private router: Router, private http: HttpClient, private SharedService: SharedService
  ) { 
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currentUser && currentUser.sessiontoken) {
          this.loggedIn.next(true);
        }
  }

  login(user: User) {
    if (user.email !== '' && user.password !== '') {
     let req = this.http.post<any>(this.getLogginUrl, user, httpOptions);
     let req2 = req;
     req2.subscribe(
      data => {
        this.logginResp = data;
        if (!data.error) {
          localStorage.setItem('currentUser', JSON.stringify(data));
          this.loggedIn.next(true);
          this.router.navigate(['/']);
        }
      }
    );
      return req;
    }
  }

  logout() {
    localStorage.removeItem('currentUser');
    this.loggedIn.next(false);
    this.router.navigate(['/login']);
  }

  register(data): Observable<any> {
    return this.http.post<any>(this.registerUrl, data, httpOptions);
  }
}
