import { NgModule } from '@angular/core';
import { HttpModule } from '@angular/http';
import { HttpClientModule }    from '@angular/common/http';
import { ReactiveFormsModule,FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppMaterialModule } from './app-material/app-material.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthGuard } from './auth/auth.guard';
import { AuthService } from './auth/auth.service';
import { HeaderComponent } from './header/header.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { CategoryComponent } from './category/category.component';
import { AddappComponent} from './addapp/addapp.component';
import {MatTabsModule,MatFormFieldModule, MatButtonModule, MatInputModule,
  MatRippleModule,
  MatToolbarModule,
  MatSelectModule,
  MatCardModule,
  MatSidenavModule,
  MatStepperModule,
  MatDialogModule,MatIconModule,MatListModule,MatTreeModule,MatCheckboxModule,MatTableModule,MatSlideToggleModule,MatRadioModule,MatBadgeModule,MatDividerModule} from '@angular/material';
import { TopicsComponent } from './topics/topics.component';
import { QuestionsComponent } from './questions/questions.component';
import { AppsComponent } from './apps/apps.component';
import { PopupComponent } from './popup/popup.component';
import { SubcategoryComponent } from './subcategory/subcategory.component';
import { CategorypopupComponent } from './categorypopup/categorypopup.component';
import { AddTopicsComponent } from './addtopics/addtopics.component';
import { AddQuestionComponent } from './addquestion/addquestion.component';
import { kbhtmlComponent } from './kbhtml/kbhtml.component';
import { addEditKbHtmlComponent } from './addeditkbhtml/addeditkbhtml.component';
import { kbTopicsComponent } from './kbtopics/kbtopics.component';
import { addEditKbTopicsComponent } from './addeditkbtopics/addeditkbtopics.component';
import { AppService } from './services/app.service';
import { CategoryService } from './services/category.service';
import { TopicsService } from './services/topics.service';
import { QuestionsService } from './services/questions.service';
import { kbhtmlService } from './services/kbhtml.service';
import { SharedService } from './services/shared.service';
import { FileUploadModule } from 'ng2-file-upload';
import { SortablejsModule } from 'angular-sortablejs/dist';
import { JwtInterceptor } from './_helpers/jwt.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AngularEditorModule } from '@kolkov/angular-editor';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    LoginComponent,
    CategoryComponent,
    AddappComponent,
    TopicsComponent,
    QuestionsComponent,
    AppsComponent,
    PopupComponent,
    SubcategoryComponent,
    CategorypopupComponent,
    AddTopicsComponent,
    AddQuestionComponent,
    kbhtmlComponent,
    addEditKbHtmlComponent,
    kbTopicsComponent,
    addEditKbTopicsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AppMaterialModule,
    MatTabsModule,
    MatSelectModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatRippleModule,
    MatSelectModule,
    MatCardModule,
    MatSidenavModule,
    MatStepperModule,
    MatDialogModule,
    FormsModule,
    MatIconModule,
    MatListModule,
    MatTreeModule,
    MatCheckboxModule,
    MatTableModule,
    HttpModule,
    HttpClientModule,
    FileUploadModule,
    SortablejsModule.forRoot({ animation: 150 }),
    MatSlideToggleModule,
    MatRadioModule,
    MatBadgeModule,
    MatDividerModule,
    NgxSpinnerModule,
    AngularEditorModule,
  ],
  entryComponents: [ PopupComponent, CategorypopupComponent],
  providers: [AuthService, AuthGuard,CategoryService,TopicsService,QuestionsService,kbhtmlService,SharedService,
    AppService,
    {
        provide: HTTP_INTERCEPTORS,
        useClass: JwtInterceptor,
        multi: true
    },],
  bootstrap: [AppComponent],
})
export class AppModule { }
