import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders, HttpResponse, HttpRequest } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpModule, Http, URLSearchParams, Headers, RequestOptions } from '@angular/http';
import { SharedService } from './shared.service';


@Injectable()
export class TopicsService {

  constructor(
    private http: HttpClient, private SharedService: SharedService) { }

  // App Topics start

  getTopics(id) {
    return this.http.get<any>(this.SharedService.getTopicUrl + id);
  }
  getTopicOption() {
    return this.http.get<any>(this.SharedService.apptopictypesUrl);
  }
  getTopicDetails(id) {
    return this.http.get<any>(this.SharedService.topicActionUrl + "/" + id);
  }
  getLevels() {
    return this.http.get<any>(this.SharedService.getTopicLevelUrl);
  }

  addTopic(data, categoryid): Observable<any> {

    let isactive = (data.isactive == true ? 1 : 0);
    let postdata = {
      "categoryid": categoryid,
      "name": data.name,
      "topictypeid": data.topictypeid,
      "isactive": isactive,
      "customicon": this.SharedService.uploadIconId,
      "sortorder": "1"
    };
    if (data.topictypeid == 'Q') {
      postdata['maxlevels'] = data.maxlevels;
      postdata['numofquestionstoclearlevel'] = data.numofquestionstoclearlevel;
      postdata['randomize'] = (data.randomize == true ? 1 : 0);
      postdata['numofquestionstodisplayinlevel'] = data.numofquestionstodisplayinlevel;
    } else if (data.topictypeid == 'H') {
      postdata['html'] = data.html;
    } else if (data.topictypeid == 'T') {
      postdata['numofquestionstodisplayinlevel'] = data.numofquestionstodisplayinlevel;
    } else {
      postdata['url'] = data.url;
    }
    this.SharedService.uploadIconId = 0;

    console.log(postdata);
    return this.http.post<any>(this.SharedService.topicActionUrl, postdata);
  }

  editTopic(data, topicid): Observable<any> {

    let isactive = (data.isactive == true ? 1 : 0);
    let postdata = {
      "categoryid": data.categoryid,
      "name": data.name,
      "topictypeid": data.topictypeid,
      "isactive": isactive,
      "sortorder": "1"
    };
    if (this.SharedService.uploadIconId) {
      postdata['customicon'] = this.SharedService.uploadIconId;
    }
    if (data.topictypeid == 'Q') {
      postdata['maxlevels'] = data.maxlevels;
      postdata['numofquestionstoclearlevel'] = data.numofquestionstoclearlevel;
      postdata['randomize'] = (data.randomize == true ? 1 : 0);
      postdata['numofquestionstodisplayinlevel'] = data.numofquestionstodisplayinlevel;
      postdata['url'] = data.url;
      postdata['html'] = data.html;
    } else if (data.topictypeid == 'H') {
      postdata['html'] = data.html;
    } else if (data.topictypeid == 'T') {
      postdata['html'] = data.html;
      postdata['numofquestionstodisplayinlevel'] = data.numofquestionstodisplayinlevel;
    } else {
      postdata['url'] = data.url;
    }
    this.SharedService.uploadIconId = 0;

    console.log(data);
    return this.http.put<any>(this.SharedService.topicActionUrl + '/' + topicid, postdata);
  }

  deleteTopics(data): Observable<any> {
    console.log(data);
    return this.http.delete<any>(this.SharedService.topicActionUrl + '/' + data.topicid);
  }

  sortTopics(data): Observable<any> {
    return this.http.put<any>(this.SharedService.appTopicSortUrl, data);
  }

  // KB Topics start

  sortKbTopics(data): Observable<any> {
    return this.http.put<any>(this.SharedService.appKbTopicSortUrl, data);
  }

  getAllKbTopics(categoryid) {
    return this.http.get<any>(this.SharedService.kbTopicUrl + categoryid);
  }

  getKbTopicDetails(kbtopicid) {
    return this.http.get<any>(this.SharedService.kbTopicActionUrl + "/" + kbtopicid);
  }

  addKbTopic(data): Observable<any> {

    let postdata = {};
    let isactive = (data.isactive == true ? 1 : 0);
    postdata = {
      "name": data.name,
      "categoryid": data.categoryid,
      "learningsequence": data.learningsequence,
      "difficultylevel": data.difficultylevel,
      "isactive": isactive,
      "sortorder": data.sortorder,
    };

    console.log(postdata);
    return this.http.post<any>(this.SharedService.kbTopicActionUrl, postdata);
  }

  editKbTopic(data): Observable<any> {

    let postdata = {};
    let isactive = (data.isactive == true ? 1 : 0);
    postdata = {
      "name": data.name,
      "categoryid": data.categoryid,
      "learningsequence": data.learningsequence,
      "difficultylevel": data.difficultylevel,
      "isactive": isactive,
      "sortorder": data.sortorder,
    };

    console.log(data);
    return this.http.put<any>(this.SharedService.kbTopicActionUrl + '/' + data.topicid, postdata);
  }

  deleteKbTopics(data): Observable<any> {
    console.log(data);
    return this.http.delete<any>(this.SharedService.kbTopicActionUrl + '/' + data.topicid);
  }



}
