import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSlideToggleModule } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { CategoryService } from '../services/category.service';
import { AppService } from '../services/app.service';
import { TopicsService } from '../services/topics.service';
import { QuestionsService } from '../services/questions.service';
import { kbhtmlService } from '../services/kbhtml.service';
import { FileUploader } from 'ng2-file-upload';
import { SharedService } from '../services/shared.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router} from '@angular/router';

@Component({
  selector: 'app-addeditkbhtml',
  templateUrl: './addeditkbhtml.component.html',
  styleUrls: ['./addeditkbhtml.component.css']
})
export class addEditKbHtmlComponent implements OnInit {

  constructor(private fb: FormBuilder, public dialog: MatDialog, private route: ActivatedRoute, private CategoryService: CategoryService, private AppService: AppService, private TopicsService: TopicsService, private QuestionsService: QuestionsService, private kbhtmlService: kbhtmlService,private SharedService:SharedService,private NgxSpinnerService:NgxSpinnerService, private router: Router) { }
  dataSource;
  res_apps;
  appid;
  categoryid;
  catname;
  apptopicid;
  kbhtmlid = 0;
  kbtopics;
  kbhtml = {
    html: "",
    kbcategoryid: "",
    kbcategoryname: "",
    kbhtmlid: "",
    kbtopicid: "",
    kbtopicname: "",
    refid: "",
    sortorder: "",
    title: "",
  };
  ngOnInit() {
    this.kbhtmlid = +this.route.snapshot.paramMap.get('kbhtmlid');
    if (this.kbhtmlid) {
      this.getKbHtmlDetails(this.kbhtmlid);
    }
    this.getAllKbTopics();
  }

  getAllKbTopics(): void {
    this.NgxSpinnerService.show();
    this.TopicsService.getAllKbTopics(0).subscribe(
      data => { 
        this.kbtopics = data.kbtopics;
        this.NgxSpinnerService.hide();
       }
    );
  }

  getKbHtmlDetails(kbhtmlid): void {
    this.NgxSpinnerService.show();
    this.kbhtmlService.getKbHtmlDetails(kbhtmlid).subscribe(
      data => {
        this.kbhtml = data.kbhtml[0];
        data.kbhtml[0].kbtopicid = data.kbhtml[0].kbtopicid.toString();
        this.NgxSpinnerService.hide();
      }
    );
  }

  onAdd(kbhtml): void {
    this.NgxSpinnerService.show();
    this.kbhtmlService.addKbHtml(kbhtml).subscribe(
      data => {
        this.NgxSpinnerService.hide();
        this.res_apps = data;
        if(data.error){
        alert(data.message);
        }else{
        this.router.navigate(['/kbhtml']);
        }
      },
    );
  }

  onEdit(kbhtml): void {
    this.NgxSpinnerService.show();
    this.kbhtmlService.editKbHtml(kbhtml).subscribe(
      data => {
        this.NgxSpinnerService.hide();
        this.res_apps = data;
        if(data.error){
          alert(data.message);
          }else{
          this.router.navigate(['/kbhtml']);
          }
      },
    );
  }


}
