import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { PopupComponent } from '../popup/popup.component';
import { ActivatedRoute } from '@angular/router';
import { CategoryService } from '../services/category.service';
import { AppService } from '../services/app.service';
import { TopicsService } from '../services/topics.service';
import { CategorypopupComponent } from '../categorypopup/categorypopup.component';
import { SharedService } from '../services/shared.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { SortablejsOptions } from 'angular-sortablejs/dist';

@Component({
  selector: 'app-topics',
  templateUrl: './topics.component.html',
  styleUrls: ['./topics.component.css']
})
export class TopicsComponent {

  constructor(private fb: FormBuilder, public dialog: MatDialog, private route: ActivatedRoute, private CategoryService: CategoryService, private AppService: AppService, private TopicsService: TopicsService, private SharedService: SharedService, private NgxSpinnerService: NgxSpinnerService) { }
  displayedColumns = ['icon', 'name', 'action'];
  dataSource;
  res_apps;
  appid;
  categoryid;
  catname;
  originalData = [];
  currentCatName = '';
  currentCatagoryId = 0;
  currentAppName = 0;
  currentAppId = 0;
  ngOnInit() {
    console.log(this.AppService.breadcrumb);
    this.getTopics();
  }

  options: SortablejsOptions = {
    onEnd: (event: any) => {
      if (event.oldIndex != event.newIndex) {
        console.log(this.originalData);
        this.sortTopics(this.originalData);
      }
    },
    "disabled": true,
  };

  enableSort() {
    this.options = {
      "disabled": !this.options.disabled,
    }
  }

  sortTopics(data): void {
    this.TopicsService.sortTopics(data).subscribe(
      data => { this.res_apps = data }
    );
  }

  getTopics(): void {
    this.NgxSpinnerService.show();
    this.categoryid = +this.route.snapshot.paramMap.get('id');
    this.TopicsService.getTopics(this.categoryid).subscribe(
      data => {
        if (data.apptopics.length) {
          this.dataSource = data.apptopics;
          this.categoryid;
          data.apptopics.forEach((element, index) => {
            this.originalData.push(element.topicid);
            // to show default icon
            var iconArray = element.icon.split('=');
            switch (element.topictypeid) {
              case 'Q': iconArray[1] = 1;
                break;
              case 'T': iconArray[1] = 2;
                break;
              case 'H': iconArray[1] = 3;
                break;
              case 'U': iconArray[1] = 4;
                break;
            }
            data.apptopics[index].icon = iconArray[0] + '=' + iconArray[1];
            // to show default icon
          });
          this.AppService.breadcrumb.splice(2, 2);
          if (this.AppService.breadcrumb[1].name == "") {
            this.AppService.setBreadcrumbApps(this.dataSource[0].appid);
          }
          this.AppService.breadcrumb[2] = { name: this.dataSource[0].categoryname, url: "topics/" + this.categoryid };
        } else {
          this.getCategory();
        }
        this.NgxSpinnerService.hide();
      }
    );
  }

  getCategory(): void {
    this.NgxSpinnerService.show();
    this.CategoryService.getCategory(this.SharedService.appid).subscribe(
      data => {
        data.appcategories.forEach(element => {
          if (element.categoryid == this.categoryid) {
            this.currentCatName = element.categoryname;
            this.currentAppName = element.appid;
            this.currentAppId = element.appid;
          }
        });
        this.AppService.setBreadcrumbApps(this.currentAppId);
        this.AppService.breadcrumb[2] = { name: this.currentCatName, url: "topics/" + this.categoryid };
        this.NgxSpinnerService.hide();
      }
    );
  }

  deleteDialog(element): void {
    let dialogRef = this.dialog.open(CategorypopupComponent, {
      width: '250px',
      data: { title: "Topic", topicid: element.topicid, "name": element.name, text: "Delete this Topic", type: "delete", btnText: 'Delete' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        this.deleteTopics(result);
      }
    });
  }

  deleteTopics(data): void {
    this.NgxSpinnerService.show();
    this.TopicsService.deleteTopics(data).subscribe(
      data => {
        this.NgxSpinnerService.hide();
        this.res_apps = data;
        if (data.error) {
          alert(data.message);
        }
        this.getTopics();
      }
    );
  }

}
