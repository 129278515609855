import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSlideToggleModule } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { CategoryService } from '../services/category.service';
import { AppService } from '../services/app.service';
import { TopicsService } from '../services/topics.service';
import { FileUploader, FileItem, ParsedResponseHeaders } from 'ng2-file-upload';
import { SharedService } from '../services/shared.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';

@Component({
  selector: 'app-addtopics',
  templateUrl: './addtopics.component.html',
  styleUrls: ['./addtopics.component.css']
})
export class AddTopicsComponent implements OnInit {

  constructor(private fb: FormBuilder, public dialog: MatDialog, private route: ActivatedRoute, private CategoryService: CategoryService, private AppService: AppService, private TopicsService: TopicsService, private SharedService: SharedService, private NgxSpinnerService: NgxSpinnerService, private Router: Router) { }
  addtopicsForm: FormGroup;
  dataSource;
  res_apps;
  appid;
  categoryid = 0;
  topicid = 0;
  catname;
  apptopictypes;
  icon_id = 0;
  levels;
  routeLink = "/topics/";
  topic = { "topicid": 0, "name": "", "categoryid": '', "categoryname": "", "appid": '', "appname": "", "customicon": "", "topictypeid": "Q", "topictypename": "", "url": "", "html": "", "maxlevels": '1', "numofquestionstoclearlevel": '10', "numofquestionstodisplayinlevel": '10', "randomize": 0, "isactive": 1, "sortorder": '1' };
  ngOnInit() {
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.uploader = new FileUploader({ url: this.SharedService.iconUploadURL, authToken: `${currentUser.sessiontoken}` });

    this.SharedService.uploadIconId = 1;
    this.SharedService.previewUrl = this.SharedService.iconGetURL + "1";
    this.categoryid = +this.route.snapshot.paramMap.get('catid');
    this.topicid = +this.route.snapshot.paramMap.get('topicid');

    // this.getTopics();
    this.getapptopictypes();
    this.getLevels();
    if (this.topicid) {
      this.getTopicDetails();
    } else {
      this.routeLink = this.routeLink + this.categoryid;
    }

    this.uploader.onErrorItem = (item, response, status, headers) => this.onErrorItem(item, response, status, headers);
    this.uploader.onSuccessItem = (item, response, status, headers) => this.onSuccessItem(item, response, status, headers);
  }

  onSuccessItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
    let data = JSON.parse(response); //success server response
    this.SharedService.uploadIconId = data.icon_id;
    this.NgxSpinnerService.hide();
  }

  onErrorItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
    // let error = JSON.parse(response); //error server response
    this.NgxSpinnerService.hide();
  }


  getapptopictypes() {
    this.NgxSpinnerService.show();
    this.TopicsService.getTopicOption().subscribe(
      data => {
        this.apptopictypes = data.apptopictypes;
        this.NgxSpinnerService.hide();
      }
    );;
  }
  getTopics(): void {
    this.NgxSpinnerService.show();
    this.TopicsService.getTopics(this.categoryid).subscribe(
      data => {
        this.dataSource = data.apptopics;
        this.catname = data.apptopics[0].categoryname;
        this.NgxSpinnerService.hide();
      }
    );
  }
  getTopicDetails(): any {
    this.NgxSpinnerService.show();
    this.TopicsService.getTopicDetails(this.topicid).subscribe(
      data => {
        this.topic = data.apptopic[0];
        this.topic.maxlevels = this.topic.maxlevels.toString();
        this.routeLink = this.routeLink + data.apptopic[0].categoryid;
        if (data.apptopic[0].customicon) {
          this.SharedService.previewUrl = this.SharedService.iconGetURL + data.apptopic[0].customicon;
        }
        this.NgxSpinnerService.hide();
      }
    );
  }

  getLevels(): void {
    this.NgxSpinnerService.show();
    this.TopicsService.getLevels().subscribe(
      data => {
        this.levels = data.levels;
        this.NgxSpinnerService.hide();
      }
    );
  }

  changePreviewURL() {
    switch (this.topic.topictypeid) {
      case 'H':
        this.SharedService.previewUrl = this.SharedService.iconGetURL + "3";
        this.SharedService.uploadIconId = 3;
        break;
      case 'Q':
        this.SharedService.previewUrl = this.SharedService.iconGetURL + "1";
        this.SharedService.uploadIconId = 1;
        break;
      case 'T':
        this.SharedService.previewUrl = this.SharedService.iconGetURL + "2";
        this.SharedService.uploadIconId = 2;
        break;
      case 'U':
        this.SharedService.previewUrl = this.SharedService.iconGetURL + "4";
        this.SharedService.uploadIconId = 4;
        break;
    }
  }

  onAdd(): void {
    this.NgxSpinnerService.show();
    this.TopicsService.addTopic(this.topic, this.categoryid).subscribe(
      data => {
        this.res_apps = data;
        this.NgxSpinnerService.hide();
        if (!data.error) {
          this.SharedService.uploadIconId = 0;
          this.Router.navigate(['/topics/' + this.categoryid]);
        }else{
          alert(data.message);
        }
      }
    );
  }

  onEdit(): void {
    this.NgxSpinnerService.show();
    this.TopicsService.editTopic(this.topic, this.topicid).subscribe(
      data => {
        this.res_apps = data;
        this.NgxSpinnerService.hide();
        if (!data.error) {
          this.SharedService.uploadIconId = 0;
          this.Router.navigate(['/topics/' + this.topic.categoryid]);
        }else{
          alert(data.message);
        }
      }
    );
  }

  public myUpload(): void {
    this.NgxSpinnerService.show();
    this.uploader.uploadAll();
  }

  public uploader: FileUploader = null;
  public hasBaseDropZoneOver: boolean = false;
  public hasAnotherDropZoneOver: boolean = false;

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  public fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e;
  }




}
