import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { PopupComponent } from '../popup/popup.component';
import { CategorypopupComponent } from '../categorypopup/categorypopup.component';
import { ActivatedRoute } from '@angular/router';
import { CategoryService } from '../services/category.service';
import { AppService } from '../services/app.service';
import { SortablejsOptions } from 'angular-sortablejs/dist';
import { SharedService } from '../services/shared.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-subcategory',
  templateUrl: './subcategory.component.html',
  styleUrls: ['./subcategory.component.css']
})
export class SubcategoryComponent implements OnInit {

  dataSource = [];
  originalData = [];
  res_apps;
  appid;
  categoryid;
  currentCatName = "";
  parentCatagory = { parentid: 1, categoryname: '', categoryid: 0 };
  currentCatagoryId = 0;
  ngOnInit() {
    this.appid = +this.route.snapshot.paramMap.get('id');
    this.categoryid = +this.route.snapshot.paramMap.get('id1');

    if (this.AppService.breadcrumb[1].name == "") {
      this.AppService.setBreadcrumbApps(this.appid);
      this.AppService.breadcrumb.splice(2, 2);
    }
    this.getCategory();
  }

  getCategory(): void {
    this.NgxSpinnerService.show();
    this.CategoryService.getCategory(this.appid).subscribe(
      data => {
        this.categoryid;
        this.dataSource = [];
        this.originalData = [];
        data.appcategories.forEach(element => {
          if (element.categoryid == this.categoryid) {
            this.currentCatName = element.categoryname;
            this.currentCatagoryId = element.parentid;
          }
          if (element.categoryid == this.currentCatagoryId) {
            this.parentCatagory = element;
          }
          if (element.parentid == this.categoryid) {
            element.hideTopic = 0;
            if (!element.topicCategory) {
              data.appcategories.forEach(subelement => {
                if (subelement.parentid == element.categoryid && subelement.topicCategory) {
                  element.hideTopic = 1;
                }
              });

            }
            this.originalData.push(element.categoryid);
            this.dataSource.push(element);
          }

        });
        if (!this.parentCatagory.parentid) {
          this.AppService.breadcrumb[2] = { name: this.parentCatagory.categoryname, url: "subcategory/" + this.appid + "/" + this.parentCatagory.categoryid };
          this.AppService.breadcrumb[3] = { name: this.currentCatName, url: "subcategory/" + this.appid + "/" + this.categoryid };
        } else {
          this.AppService.breadcrumb[2] = { name: this.currentCatName, url: "subcategory/" + this.appid + "/" + this.categoryid };
        }
        this.NgxSpinnerService.hide();
      }
    );
  }

  options: SortablejsOptions = {
    onEnd: (event: any) => {
      if (event.oldIndex != event.newIndex) {
        this.sortCategory(this.originalData);
      }
    },
    "disabled": true,
  };

  enableSort() {
    this.options = {
      "disabled": !this.options.disabled,
    }
  }

  sortCategory(data): void {
    this.CategoryService.sortCategory(data).subscribe(
      data => { this.res_apps = data }
    );
  }

  editCategory(data): void {
    this.CategoryService.editCategory(data).subscribe(
      data => {
        this.NgxSpinnerService.hide();
        this.res_apps = data;
        this.getCategory();
        if (data.error) {
          alert(data.message);
        }
      }
    );
  }

  addCategory(data): void {
    this.CategoryService.addCategory(data).subscribe(
      data => {
        this.NgxSpinnerService.hide();
        this.res_apps = data;
        if (data.error) {
          alert(data.message);
        }
      }
    );
    this.getCategory();
  }

  deleteCategory(data): void {
    this.CategoryService.deleteCategory(data).subscribe(
      data => {
        this.NgxSpinnerService.hide();
        this.res_apps = data;
        if (data.error) {
          alert(data.message);
        }
      }
    );
    this.getCategory();
  }


  constructor(private fb: FormBuilder, public dialog: MatDialog, private route: ActivatedRoute, private CategoryService: CategoryService, private AppService: AppService, private SharedService: SharedService, private NgxSpinnerService: NgxSpinnerService) { }

  displayedColumns = ['position', 'name', 'weight'];

  categoryDialog(parentId): void {
    let dialogRef = this.dialog.open(CategorypopupComponent, {
      width: '300px',
      data: { title: "Category", name: "Category 1", text: "Category Name", parent_id: parentId }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      // this.title = result;
    });
  }

  addDialog(): void {
    this.SharedService.uploadIconId = 0;
    let dialogRef = this.dialog.open(CategorypopupComponent, {
      width: '370px',
      data: { "title": "Sub-Category", categoryname: '', "text": "Sub-Category Name", "appid": this.appid, "parentid": this.categoryid, "type": "add", "btnText": 'Add' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        this.NgxSpinnerService.show();
        this.addCategory(result);
      }
      this.SharedService.previewUrl = this.SharedService.iconGetURL + "0";
    });
  }

  editDialog(element): void {
    this.SharedService.uploadIconId = 0;
    this.SharedService.previewUrl = element.icon;
    let dialogRef = this.dialog.open(CategorypopupComponent, {
      width: '370px',
      data: { "title": "Category", "categoryname": element.categoryname, "appid": element.appid, "categoryid": element.categoryid, "parentid": element.parentid, "isactive": element.isactive, "sortorder": element.sortorder, "text": "Category Name", "type": "edit", "btnText": 'Edit' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        this.NgxSpinnerService.show();
        console.log(result);
        this.editCategory(result);
      }
      this.SharedService.previewUrl = this.SharedService.iconGetURL + "0";
    });
  }

  deleteDialog(element): void {
    let dialogRef = this.dialog.open(CategorypopupComponent, {
      width: '250px',
      data: { "title": "Category", "categoryid": element.categoryid, "name": element.categoryname, "text": "Delete this Category", "type": "delete", "btnText": 'Delete' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        this.NgxSpinnerService.show();
        this.deleteCategory(result);
      }
    });
  }

}
