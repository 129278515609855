import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { PopupComponent } from '../popup/popup.component';
import { ActivatedRoute } from '@angular/router';
import { CategoryService } from '../services/category.service';
import { AppService } from '../services/app.service';
import { TopicsService } from '../services/topics.service';
import { kbhtmlService } from '../services/kbhtml.service';
import { CategorypopupComponent } from '../categorypopup/categorypopup.component';
import { SharedService } from '../services/shared.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { SortablejsOptions } from 'angular-sortablejs/dist';

@Component({
  selector: 'app-kbtopics',
  templateUrl: './kbtopics.component.html',
  styleUrls: ['./kbtopics.component.css']
})
export class kbTopicsComponent {

  constructor(private fb: FormBuilder, public dialog: MatDialog, private route: ActivatedRoute, private CategoryService: CategoryService, private AppService: AppService, private TopicsService: TopicsService, private kbhtmlService: kbhtmlService, private SharedService: SharedService, private NgxSpinnerService: NgxSpinnerService) { }
  displayedColumns = ['name', 'weight'];
  dataSource;
  res_apps;
  appid;
  categoryid = 0;
  catname;
  originalData = [];
  ngOnInit() {
    this.categoryid = +this.route.snapshot.paramMap.get('categoryid');
    this.getAllKbTopics(this.categoryid);
    this.AppService.breadcrumb[1] = { name: "KB Topic", url: "kbtopics/" };
    this.AppService.breadcrumb.splice(2, 1);
  }

  options: SortablejsOptions = {
    onEnd: (event: any) => {
      if (event.oldIndex != event.newIndex) {
        console.log(this.originalData);
        this.sortKbTopics(this.originalData);
      }
    },
    "disabled": true,
  };

  enableSort() {
    this.options = {
      "disabled": !this.options.disabled,
    }
  }

  sortKbTopics(data): void {
    this.TopicsService.sortKbTopics(data).subscribe(
      data => { this.res_apps = data }
    );
  }

  getAllKbTopics(categoryid): void {
    this.NgxSpinnerService.show();
    this.TopicsService.getAllKbTopics(categoryid).subscribe(
      data => {
        this.dataSource = data.kbtopics;
        this.categoryid;
        data.kbtopics.forEach((element, index) => {
          this.originalData.push(element.topicid);
        });
        this.NgxSpinnerService.hide();
      }
    );
  }

  deleteDialog(element): void {
    let dialogRef = this.dialog.open(CategorypopupComponent, {
      width: '250px',
      data: { title: "Topic", topicid: element.topicid, "name": element.name, text: "Delete this Topic", type: "delete", btnText: 'Delete' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        this.deleteTopics(result);
      }
    });
  }

  deleteTopics(data): void {
    this.NgxSpinnerService.show();
    this.TopicsService.deleteKbTopics(data).subscribe(
      data => {
        this.NgxSpinnerService.hide();
        this.res_apps = data;
        alert(data.message);
      }
    );
    this.getAllKbTopics(this.categoryid);
  }

}
