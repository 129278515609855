import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './auth/auth.guard';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { CategoryComponent } from './category/category.component';
import { AddappComponent } from './addapp/addapp.component';
import { TopicsComponent } from './topics/topics.component';
import { QuestionsComponent } from './questions/questions.component';
import { AppsComponent } from './apps/apps.component';
import { SubcategoryComponent } from './subcategory/subcategory.component';
import { AddTopicsComponent } from './addtopics/addtopics.component';
import { AddQuestionComponent } from './addquestion/addquestion.component';
import { kbhtmlComponent } from './kbhtml/kbhtml.component';
import { addEditKbHtmlComponent } from './addeditkbhtml/addeditkbhtml.component';
import { kbTopicsComponent } from './kbtopics/kbtopics.component';
import { addEditKbTopicsComponent } from './addeditkbtopics/addeditkbtopics.component';

const routes: Routes = [
  { path: 'kbtopics', component: kbTopicsComponent, canActivate: [AuthGuard] },
  { path: 'kbtopics/:categoryid', component: kbTopicsComponent, canActivate: [AuthGuard] },
  { path: 'addkbtopics', component: addEditKbTopicsComponent, canActivate: [AuthGuard] },
  { path: 'editkbtopics/:topicid', component: addEditKbTopicsComponent, canActivate: [AuthGuard] },
  { path: 'addtopics/:catid', component: AddTopicsComponent, canActivate: [AuthGuard] },
  { path: 'edittopics/:topicid', component: AddTopicsComponent, canActivate: [AuthGuard] },
  { path: 'kbhtml', component: kbhtmlComponent, canActivate: [AuthGuard] },
  { path: 'addkbhtml', component: addEditKbHtmlComponent, canActivate: [AuthGuard] },
  { path: 'editkbhtml/:kbhtmlid', component: addEditKbHtmlComponent, canActivate: [AuthGuard] },
  { path: 'subcategory/:id/:id1', component: SubcategoryComponent, canActivate: [AuthGuard],data: { breadcrumb: 'Sub-Category'} },
  { path: 'topics/:id', component: TopicsComponent, canActivate: [AuthGuard] },
  { path: 'questions', component: QuestionsComponent, canActivate: [AuthGuard] },
  { path: 'questionstotopic/:topicid', component: QuestionsComponent, canActivate: [AuthGuard] },
  { path: 'addquestion', component: AddQuestionComponent, canActivate: [AuthGuard] },
  { path: 'editquestion/:qid', component: AddQuestionComponent, canActivate: [AuthGuard] },
  { path: 'apps', component: AppsComponent, canActivate: [AuthGuard] },
  { path: 'category/:id', component: CategoryComponent, canActivate: [AuthGuard],data: { breadcrumb: 'Category'} },
  { path: '', component: HomeComponent, canActivate: [AuthGuard],data: { breadcrumb: 'Home'} },
  { path: 'login', component: LoginComponent },
  { path: 'addapp', component: AddappComponent },
  { path: '**', redirectTo: ''}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
