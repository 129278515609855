import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { PopupComponent } from '../popup/popup.component';
import { CategorypopupComponent } from '../categorypopup/categorypopup.component';
import { ActivatedRoute } from '@angular/router';
import { CategoryService } from '../services/category.service';
import { AppService } from '../services/app.service';
import { async } from '@angular/core/testing';
import { SortablejsOptions } from 'angular-sortablejs/dist';
import { Title } from '@angular/platform-browser';
import { SharedService } from '../services/shared.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-category',
  templateUrl: './category.component.html',
  styleUrls: ['./category.component.css']
})
export class CategoryComponent implements OnInit {

  res_apps;
  appid;
  dataSource = [];
  originalData = [];
  hasTopic = [];
  ngOnInit() {
    this.appid = +this.route.snapshot.paramMap.get('id');
    this.getCategory();
    this.AppService.setBreadcrumbApps(this.appid);
    this.AppService.breadcrumb.splice(2, 2);
  }

  getCategory(): void {
    this.NgxSpinnerService.show();
    this.CategoryService.getCategory(this.appid).subscribe(
      data => {
        this.dataSource = [];
        this.originalData = [];
        data.appcategories.forEach(element => {

          if (element.parentid == 0) {
            element.hideTopic = 0;
           
            if (!element.topicCategory) {

              data.appcategories.forEach(subelement => {
                if (subelement.parentid == element.categoryid && subelement.topicCategory) {
                  element.hideTopic = 1;
                }
              });

            }
            this.originalData.push(element.categoryid);
            this.dataSource.push(element);
          }
        });
        this.NgxSpinnerService.hide();
      }
    );
  }

  editCategory(data): void {
    // console.log(data);
    this.CategoryService.editCategory(data).subscribe(
      data => {
        this.res_apps = data;
        this.NgxSpinnerService.hide();
        this.getCategory();
        if(data.error){
          alert(data.message);
        }
      }
    );
  }

  sortCategory(data): void {
    this.CategoryService.sortCategory(data).subscribe(
      data => { this.res_apps = data }
    );
  }


  addCategory(data): void {
    this.CategoryService.addCategory(data).subscribe(
      data => {
        this.res_apps = data;
        this.NgxSpinnerService.hide();
        this.getCategory();
        if(data.error){
          alert(data.message);
        }
      }
    );
  }

  deleteCategory(data): void {
    this.CategoryService.deleteCategory(data).subscribe(
      data => {
        this.res_apps = data;
        this.NgxSpinnerService.hide();
        this.getCategory();
        if(data.error){
          alert(data.message);
        }
      }
    );
  }

  options: SortablejsOptions = {
    onEnd: (event: any) => {
      if (event.oldIndex != event.newIndex) {
        this.sortCategory(this.originalData);
      }
    },
    "disabled": true,
  };

  enableSort() {
    this.options = {
      "disabled": !this.options.disabled,
    }
  }


  constructor(private fb: FormBuilder, public dialog: MatDialog, private route: ActivatedRoute, private CategoryService: CategoryService, private AppService: AppService, private titleService: Title,private SharedService:SharedService,private NgxSpinnerService:NgxSpinnerService) {

  }
  displayedColumns = ['position', 'name', 'weight'];

  categoryDialog(parentId): void {
    let dialogRef = this.dialog.open(CategorypopupComponent, {
      width: '300px',
      data: { title: "Category", name: "Category 1", text: "Category Name", parent_id: parentId }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      // this.title = result;
    });
  }

  addDialog(): void {
    this.SharedService.uploadIconId = 0;
    let dialogRef = this.dialog.open(CategorypopupComponent, {
      width: '370px',
      data: { title: "Category", categoryname: '', text: "Category Name", appid: this.appid, type: "add", btnText: 'Add', "parentid": 0}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        this.NgxSpinnerService.show();
        this.addCategory(result);
      }
      this.SharedService.previewUrl = this.SharedService.iconGetURL+"0";
    });
  }

  editDialog(element): void {
    this.SharedService.uploadIconId = 0;
    this.SharedService.previewUrl = element.icon;
    let dialogRef = this.dialog.open(CategorypopupComponent, {
      width: '370px',
      data: { "title": "Category", "categoryname": element.categoryname, "appid": element.appid, "categoryid": element.categoryid, "parentid": element.parentid, "isactive": element.isactive, "sortorder": element.sortorder, "text": "Category Name", "type": "edit", "btnText": 'Edit' }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result);
      if (result != undefined) {
        this.NgxSpinnerService.show();
        this.editCategory(result);
      }
      this.SharedService.previewUrl = this.SharedService.iconGetURL+"0";
    });
  }

  deleteDialog(element): void {
    let dialogRef = this.dialog.open(CategorypopupComponent, {
      width: '250px',
      data: { title: "Category", categoryid: element.categoryid, "name": element.categoryname, text: "Delete this Category", type: "delete", btnText: 'Delete' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        this.NgxSpinnerService.show();
        this.deleteCategory(result);
      }
    });
  }
}


