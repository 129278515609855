import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  template: `
  <app-header></app-header>
  `,
  styles: []
})
export class AppComponent { }
