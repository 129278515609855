import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  form: FormGroup;
  registerForm: FormGroup;
  private formSubmitAttempt: boolean;

  constructor(private fb: FormBuilder, private authService: AuthService, private router: Router, ) { }

  ngOnInit() {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required]
    });

    this.registerForm = this.fb.group({
      firstname: ['', [Validators.required, Validators.maxLength(32)]],
      lastname: ['', [Validators.required, Validators.maxLength(32)]],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(8)]],
      confPassword: ['', [Validators.required, Validators.minLength(8)]],
      invitecode: ['', Validators.required],
    });
  }

  isFieldInvalid(field: string) {
    return (
      (!this.form.get(field).valid && this.form.get(field).touched) ||
      (this.form.get(field).untouched && this.formSubmitAttempt)
    );
  }

  logginResp;
  errors = "";
  registerErrors = "";
  onSubmit() {
    if (this.form.valid) {
      this.logginResp = this.authService.login(this.form.value);
      this.logginResp.subscribe(
        data => {
          if (data.error) {
            this.errors = data.message;
          }
        }
      );
    }
    this.formSubmitAttempt = true;
  }

register(): void {
    if (this.registerForm.valid) {
      this.authService.register(this.registerForm.value).subscribe(
        data => {
          alert(data.message);
        },
      );
    } else {
      this.registerErrors = "Please Fill all Fields";
    }
    this.formSubmitAttempt = true;
  }

}
