import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material';
import {PopupComponent} from '../popup/popup.component';
import { AppService } from '../services/app.service';
import { HomeComponent } from '../home/home.component';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-apps',
  templateUrl: './apps.component.html',
  styleUrls: ['./apps.component.css']
})
export class AppsComponent implements OnInit  {

  public res_apps = {};
  dataSource;
  ngOnInit() {
    this.getApps();
  }

  getApps(): void {
    this.AppService.getApps().subscribe(
      data => { this.dataSource = data.apps }
    );
  }

  addApp(data): void {
    this.AppService.addApp(data).subscribe(
      data => { this.res_apps = data }
    );
  }

  constructor(private fb: FormBuilder,public dialog: MatDialog,private AppService: AppService) { }
  displayedColumns = ['position', 'name', 'weight'];

  addDialog(): void {
    let dialogRef = this.dialog.open(PopupComponent, {
      width: '250px',
      data: { title: "Apps", name: "", text: "Apps Name", "btnText": 'Add' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
      this.addApp(result);
      console.log('The dialog was closed');
      // this.title = result;
      }
    });
  }

  editDialog(): void {
    let dialogRef = this.dialog.open(PopupComponent, {
      width: '250px',
      data: { title: "Apps", name: "", text: "Apps Name", "btnText": 'Edit' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
      console.log('The dialog was closed');
      // this.title = result;
      }
    });
  }

  deleteDialog(id): void {
    let dialogRef = this.dialog.open(PopupComponent, {
      width: '250px',
      data: { title: "Apps", name: "", text: "Delete this Apps", type:"delete", appid: id, "btnText": 'Delete' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
      console.log('The dialog was closed');
      // this.title = result;
      }
    });
  }
}
