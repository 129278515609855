import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatTableDataSource } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { PopupComponent } from '../popup/popup.component';
import { QuestionsService } from '../services/questions.service';
import { CategorypopupComponent } from '../categorypopup/categorypopup.component';
import { AppService } from '../services/app.service';
import { SharedService } from '../services/shared.service';
import { TopicsService } from '../services/topics.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { SelectionModel } from '@angular/cdk/collections';
import { Router } from '@angular/router';

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.css']
})
export class QuestionsComponent {

  constructor(private fb: FormBuilder, public dialog: MatDialog, private QuestionsService: QuestionsService, private route: ActivatedRoute, private AppService: AppService, private TopicsService: TopicsService, private SharedService: SharedService, private NgxSpinnerService: NgxSpinnerService, private router: Router) { }
  displayedColumns = ['select', 'category', 'topic', 'qtext', 'type', 'action'];
  dataSource;
  topicid = 0;
  apptopics;
  selectedTopicId = 0;
  selectedCatagoryId = 0;
  categorynames;
  topicnames;
  typenames;
  categorynames1;
  topicnames1;
  typenames1;
  questionsNotInTopic;
  questionsInTopic;
  selectedTopicName = '';
  dataSourceFilter = { 'categorynames': '', 'topicnames': '', 'questions': '', 'typenames': '' };
  questionsNotInTopicFilter = { 'categorynames': '', 'topicnames': '', 'questions': '', 'typenames': '' };
  selectedTab = 0;

  selection = new SelectionModel<any>(true, []);
  selectionDataSource = new SelectionModel<any>(true, []);

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.questionsNotInTopic.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.questionsNotInTopic.data.forEach(row => this.selection.select(row));
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelectedDataSource() {
    const numSelected = this.selectionDataSource.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selectionDataSource. */
  masterToggleDataSource() {
    this.isAllSelectedDataSource() ?
      this.selectionDataSource.clear() :
      this.dataSource.data.forEach(row => this.selectionDataSource.select(row));
  }

  clearFilter(type) {
    if (type) {
      this.questionsNotInTopicFilter = { 'categorynames': '', 'topicnames': '', 'questions': '', 'typenames': '' };
      this.questionsNotInTopic.filter = '';
    } else {
      this.dataSourceFilter = { 'categorynames': '', 'topicnames': '', 'questions': '', 'typenames': '' };
      this.dataSource.filter = '';
    }
  }

  applyFilter(filterValue: string, type) {
    if (type) {
      this.questionsNotInTopic.filterPredicate = ((data, filter: string) => {
        if (this.questionsNotInTopicFilter.categorynames) {
          if (data.kbcategoryname.toLowerCase().indexOf(this.questionsNotInTopicFilter.categorynames.toLowerCase()) == -1) {
            return false;
          }
        }
        if (this.questionsNotInTopicFilter.topicnames) {
          if (data.kbtopicname.toLowerCase().indexOf(this.questionsNotInTopicFilter.topicnames.toLowerCase()) == -1) {
            return false;
          }
        }
        if (this.questionsNotInTopicFilter.questions) {
          if (data.qtext) {
            if (data.qtext.toLowerCase().indexOf(this.questionsNotInTopicFilter.questions.toLowerCase()) == -1) {
              return false;
            }
          }
        }
        if (this.questionsNotInTopicFilter.typenames) {
          if (data.qtypetext.toLowerCase().indexOf(this.questionsNotInTopicFilter.typenames.toLowerCase()) == -1) {
            return false;
          }
        }
        return true;
      });
      this.questionsNotInTopic.filter = filterValue.trim().toLowerCase();
    } else {
      this.dataSource.filterPredicate = ((data, filter: string) => {

        if (this.dataSourceFilter.categorynames) {
          if (data.kbcategoryname.toLowerCase().indexOf(this.dataSourceFilter.categorynames.toLowerCase()) == -1) {
            return false;
          }
        }
        if (this.dataSourceFilter.topicnames) {
          if (data.kbtopicname.toLowerCase().indexOf(this.dataSourceFilter.topicnames.toLowerCase()) == -1) {
            return false;
          }
        }
        if (this.dataSourceFilter.questions) {
          if (data.qtext) {
            if (data.qtext.toLowerCase().indexOf(this.dataSourceFilter.questions.toLowerCase()) == -1) {
              return false;
            }
          }
        }
        if (this.dataSourceFilter.typenames) {
          if (data.qtypetext.toLowerCase().indexOf(this.dataSourceFilter.typenames.toLowerCase()) == -1) {
            return false;
          }
        }
        return true;

      });
      this.dataSource.filter = filterValue.trim().toLowerCase();
    }
  }

  ngOnInit() {
    this.selectedTopicId = +this.route.snapshot.paramMap.get('topicid');
    if (this.selectedTopicId) {
      this.getTopicDetails(this.selectedTopicId);
      this.getQuestionsInTopic(this.selectedTopicId);
      this.getQuestionsNotInTopic(this.selectedTopicId);
    } else {
      this.getKbQuestions(this.topicid);
      this.AppService.breadcrumb[1] = { name: "Question Bank", url: "questions/" };
      this.AppService.breadcrumb.splice(2, 1);
    }
    // this.getTopics(0);
  }

  getTopicDetails(selectedTopicId): void {
    this.NgxSpinnerService.show();
    this.TopicsService.getTopicDetails(selectedTopicId).subscribe(
      data => {
        this.selectedCatagoryId = data.apptopic[0].categoryid;
        this.selectedTopicName = data.apptopic[0].name;
        this.AppService.breadcrumb[2] = { name: data.apptopic[0].categoryname, url: "topics/" + this.selectedCatagoryId };
        this.AppService.breadcrumb[3] = { name: this.selectedTopicName, url: "questions/" + this.selectedTopicId };
        this.NgxSpinnerService.hide();
      }
    );
  }

  addToTopic(): void {
    this.NgxSpinnerService.show();
    this.QuestionsService.addQuestionToTopic(this.selection.selected, this.selectedTopicId).subscribe(
      data => {
        this.NgxSpinnerService.hide();
        if (data.error) {
          alert(data.message);
        }
        this.selectedTab = 0;
        this.getQuestionsInTopic(this.selectedTopicId);
        this.getQuestionsNotInTopic(this.selectedTopicId);
        // this.router.navigate(['/topics/' + this.selectedCatagoryId]);
      }
    );
  }

  addToTopicDialog(): void {
    let dialogRef = this.dialog.open(PopupComponent, {
      width: '370px',
      data: { title: "Add Question to Topic", name: "", text: "", type: "addToTopic", "btnText": 'Add', "apptopics": this.apptopics, "selectedTopicId": '' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        this.NgxSpinnerService.show();
        this.QuestionsService.addQuestionToTopic(this.selection.selected, result.selectedTopicId).subscribe(
          data => {
            this.NgxSpinnerService.hide();
            alert(data.message);
          }
        );
      }
    });
  }

  getQuestionsInTopic(topicid): void {
    this.NgxSpinnerService.show();
    //  this.topicid = +this.route.snapshot.paramMap.get('id');
    this.QuestionsService.getQuestionsInTopic(topicid).subscribe(
      data => {
        // this.dataSource = data.kbquestions;
        this.dataSource = new MatTableDataSource<any>(data.appquestions);
        // select distinct Category
        const temp = data.appquestions.map(data => data.kbcategoryname);
        this.categorynames = temp.filter((x, i, a) => x && a.indexOf(x) === i);
        // select distinct topics
        const temp1 = data.appquestions.map(data => data.kbtopicname);
        this.topicnames = temp1.filter((x, i, a) => x && a.indexOf(x) === i);
        // select distinct type
        const temp2 = data.appquestions.map(data => data.qtypetext);
        this.typenames = temp2.filter((x, i, a) => x && a.indexOf(x) === i);
        this.NgxSpinnerService.hide();
      }
    );
  }

  getQuestionsNotInTopic(topicid): void {
    this.NgxSpinnerService.show();
    //  this.topicid = +this.route.snapshot.paramMap.get('id');
    this.QuestionsService.getQuestionsNotInTopic(topicid).subscribe(
      data => {
        // this.dataSource = data.kbquestions;
        this.questionsNotInTopic = new MatTableDataSource<any>(data.appquestions);
        // select distinct Category
        const temp = data.appquestions.map(data => data.kbcategoryname);
        this.categorynames1 = temp.filter((x, i, a) => x && a.indexOf(x) === i);
        // select distinct topics
        const temp1 = data.appquestions.map(data => data.kbtopicname);
        this.topicnames1 = temp1.filter((x, i, a) => x && a.indexOf(x) === i);
        // select distinct type
        const temp2 = data.appquestions.map(data => data.qtypetext);
        this.typenames1 = temp2.filter((x, i, a) => x && a.indexOf(x) === i);
        this.NgxSpinnerService.hide();
      }
    );
  }

  getKbQuestions(topicid): void {
    this.NgxSpinnerService.show();
    //  this.topicid = +this.route.snapshot.paramMap.get('id');
    this.QuestionsService.getKbQuestions(topicid).subscribe(
      data => {
        // this.dataSource = data.kbquestions;
        this.dataSource = new MatTableDataSource<any>(data.kbquestions);
        // select distinct Category
        const temp = data.kbquestions.map(data => data.kbcategoryname);
        this.categorynames = temp.filter((x, i, a) => x && a.indexOf(x) === i);
        // select distinct topics
        const temp1 = data.kbquestions.map(data => data.kbtopicname);
        this.topicnames = temp1.filter((x, i, a) => x && a.indexOf(x) === i);
        // select distinct type
        const temp2 = data.kbquestions.map(data => data.qtypetext);
        this.typenames = temp2.filter((x, i, a) => x && a.indexOf(x) === i);
        this.NgxSpinnerService.hide();
      }
    );
  }

  deleteDialog(element): void {
    let dialogRef = this.dialog.open(CategorypopupComponent, {
      width: '250px',
      data: { title: "Question", name: "", text: "Delete this Question", type: "delete", "btnText": 'Delete', qid: element.qid }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        this.deleteQuestion(result);
      }
    });
  }

  deleteQuestion(data): void {
    this.NgxSpinnerService.show();
    this.QuestionsService.deleteKbQuestion(data).subscribe(
      data => {
        this.NgxSpinnerService.hide();
        if (data.error) {
          alert(data.message);
        }
        this.getKbQuestions(this.topicid);
      }
    );
  }

  deleteQuestionFromTopicDialog(element): void {
    let dialogRef = this.dialog.open(CategorypopupComponent, {
      width: '250px',
      data: { title: "Question", name: " Question From this Topic?", text: "Delete Question From this Topic?", type: "delete", "btnText": 'Delete', selectedTopicId: this.selectedTopicId, qid: element.qid }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != undefined) {
        this.deleteQuestionFromTopic(result);
      }
    });
  }

  deleteQuestionFromTopic(data): void {
    this.NgxSpinnerService.show();
    var postdata = { selectedTopicId: this.selectedTopicId, qid: data.qid };
    this.QuestionsService.deleteQuestionFromTopic(postdata).subscribe(
      data => {
        this.NgxSpinnerService.hide();
        if (data.error) {
          alert(data.message);
        }
        this.getQuestionsInTopic(this.selectedTopicId);
        this.getQuestionsNotInTopic(this.selectedTopicId);
      }
    );
  }

  // deleteQuestionFromTopic(): void {
  //   this.NgxSpinnerService.show();
  //   this.QuestionsService.deleteQuestionFromTopic(this.selectionDataSource.selected, this.selectedTopicId).subscribe(
  //     data => {
  //       this.NgxSpinnerService.hide();
  //       alert(data.message);
  //     }
  //   );
  //   this.getQuestionsInTopic(this.selectedTopicId);
  //   this.getQuestionsNotInTopic(this.selectedTopicId);
  // }

}

