import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders, HttpResponse, HttpRequest } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpModule, Http, URLSearchParams, Headers, RequestOptions } from '@angular/http';
import { SharedService } from './shared.service';

@Injectable()
export class AppService {

    currentAppName = "";
    currentApp = {appid: 0, appname: "", icon: ""};
    breadcrumb = [];

    getApps() {
        return this.http.get<any>(this.SharedService.getAppsUrl);
    }

    addApp(data) {
        let postdata = {
            "name": data,
        }
        return this.http.put<any>(this.SharedService.appsActionUrl, postdata);
    }

    editApp(data): Observable<any> {
        let postdata = {};
        postdata = {
            "name": data.name,
        }
        console.log(data);
        return this.http.put<any>(this.SharedService.appsActionUrl + '/' + data.appid, postdata);
    }

    deleteApp(data): Observable<any> {
        console.log(data);
        return this.http.delete<any>(this.SharedService.appsActionUrl + '/' + data.appid);
    }

    setBreadcrumbApps(appid): void {
        this.getApps().subscribe(
          data => { 
            data.apps.forEach(element => {
              if (element.appid == appid) {
                this.currentApp = element;
                this.SharedService.appid = appid;
                this.breadcrumb[1] = {name: this.currentApp.appname, url: "category/"+appid};
              }
            });
           }
        );
      }

    constructor(
        private http: HttpClient,private SharedService:SharedService) {
            this.breadcrumb[0] = {name: "Home", url:"/"};
            this.breadcrumb[1] = {name: "", url:"/"};
            this.breadcrumb[2] = {name: "", url:"/"};
    }

}
