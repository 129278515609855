import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders, HttpResponse, HttpRequest } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpModule, Http, URLSearchParams, Headers, RequestOptions } from '@angular/http';
import { SharedService } from './shared.service';


@Injectable()
export class QuestionsService {

  getQuestions(id) {
    return this.http.get<any>(this.SharedService.getQuestionsInTopicUrl + id);
  }

  getKbQuestionDetails(qid) {
    return this.http.get<any>(this.SharedService.kbQuestionsActionUrl + "/" + qid);
  }

  getKbQuestions(topicId) {
    return this.http.get<any>(this.SharedService.kbQuestionsAllUrl + "/" + topicId);
  }

  getQuestionTypes() {
    return this.http.get<any>(this.SharedService.getQuestionsTypeUrl);
  }
  getQuestionsInTopic(topicid) {
    return this.http.get<any>(this.SharedService.getQuestionsInTopicUrl+topicid);
  }
  getQuestionsNotInTopic(topicid) {
    return this.http.get<any>(this.SharedService.getQuestionsNotInTopicUrl+topicid);
  }

  // getTopicDetails(id) {
  //   return this.http.get<any>(this.topicActionUrl +"/" + id);
  // }

  addQuestionToTopic(questions, topicid): Observable<any> {
    let que = [];
    questions.forEach(element => {
      que.push({ 'qid': element.qid, 'sortorder': 0 });
    });
    let postdata = {
      "topicid": topicid,
      "questions": que,
    }
    return this.http.post<any>(this.SharedService.addQueToTopicUrl, postdata);
  }

  addKbQuestion(data, apptopicid): Observable<any> {

    let postdata = {};
    let isactive = (data.isactive == true ? 1 : 0);
    if (data.qtypeid == 1 || data.qtypeid == 2) {
      postdata = {
        // "apptopicid": apptopicid,
        "kbtopicid": data.kbtopicid,
        "levelid": data.levelid,
        "qtypeid": data.qtypeid,
        "qtext": data.qtext,
        "qexplaintext": data.qexplaintext,
        "op1text": data.op1text,
        "op2text": data.op2text,
        "op3text": data.op3text,
        "op4text": data.op4text,
        "op5text": data.op5text,
        "isop1": (data.isop1 == true || data.isop == 1 ? 1 : 0),
        "isop2": (data.isop2 == true || data.isop == 2 ? 1 : 0),
        "isop3": (data.isop3 == true || data.isop == 3 ? 1 : 0),
        "isop4": (data.isop4 == true || data.isop == 4 ? 1 : 0),
        "isop5": (data.isop5 == true || data.isop == 5 ? 1 : 0),
        "isactive": isactive,
        "sortorder": "1",
      };
    } else if (data.qtypeid == 3 || data.qtypeid == 7) {
      postdata = {
        "kbtopicid": data.kbtopicid,
        "qtypeid": data.qtypeid,
        "qtext": data.qtext,
        "op1text": data.op1text,
        "qexplaintext": data.qexplaintext
      };
    }

    console.log(postdata);
    return this.http.post<any>(this.SharedService.kbQuestionsActionUrl, postdata);
  }

  editKbQuestion(data, topicid): Observable<any> {

    let postdata = {};
    let isactive = (data.isactive == true ? 1 : 0);
    if (data.qtypeid == 1 || data.qtypeid == 2) {
      postdata = {
        // "apptopicid": apptopicid,
        "kbtopicid": data.kbtopicid,
        "levelid": data.levelid,
        "qtypeid": data.qtypeid,
        "qtext": data.qtext,
        "qexplaintext": data.qexplaintext,
        "op1text": data.op1text,
        "op2text": data.op2text,
        "op3text": data.op3text,
        "op4text": data.op4text,
        "op5text": data.op5text,
        "isop1": (data.isop1 == true || data.isop == 1 ? 1 : 0),
        "isop2": (data.isop2 == true || data.isop == 2 ? 1 : 0),
        "isop3": (data.isop3 == true || data.isop == 3 ? 1 : 0),
        "isop4": (data.isop4 == true || data.isop == 4 ? 1 : 0),
        "isop5": (data.isop5 == true || data.isop == 5 ? 1 : 0),
        "isactive": isactive,
        "sortorder": "1",
      };
    } else if (data.qtypeid == 3 || data.qtypeid == 7) {
      postdata = {
        "kbtopicid": data.kbtopicid,
        "qtypeid": data.qtypeid,
        "qtext": data.qtext,
        "op1text": data.op1text,
        "qexplaintext": data.qexplaintext
      };
    }

    console.log(postdata);
    return this.http.put<any>(this.SharedService.kbQuestionsActionUrl + '/' + data.qid, postdata);
  }

  deleteKbQuestion(data): Observable<any> {
    return this.http.delete<any>(this.SharedService.kbQuestionsActionUrl + '/' + data.qid);
  }

  deleteQuestionFromTopic(data): Observable<any> {
    return this.http.delete<any>(this.SharedService.deleteQuestionFromTopicUrl + '/' + data.selectedTopicId + '/' + data.qid);
  }

  // deleteQuestionFromTopic(questions, topicid): Observable<any> {
  //   let que = [];
  //   questions.forEach(element => {
  //     que.push(element.qid);
  //   });
  //   let postdata = {
  //     "topicid": topicid,
  //     "questions": que,
  //   }
  //   return this.http.delete<any>(this.SharedService.deleteQuestionFromTopicUrl + '/' + topicid, postdata);
  //   // return this.http.post<any>(this.SharedService.addQueToTopicUrl, postdata);
  // }

  constructor(
    private http: HttpClient, private SharedService: SharedService) { }

}
