import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSlideToggleModule } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { CategoryService } from '../services/category.service';
import { AppService } from '../services/app.service';
import { TopicsService } from '../services/topics.service';
import { QuestionsService } from '../services/questions.service';
import { FileUploader } from 'ng2-file-upload';
import { SharedService } from '../services/shared.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router} from '@angular/router';

@Component({
  selector: 'app-addquestion',
  templateUrl: './addquestion.component.html',
  styleUrls: ['./addquestion.component.css']
})
export class AddQuestionComponent implements OnInit {

  constructor(private fb: FormBuilder, public dialog: MatDialog, private route: ActivatedRoute, private CategoryService: CategoryService, private AppService: AppService, private TopicsService: TopicsService, private QuestionsService: QuestionsService, private SharedService: SharedService, private NgxSpinnerService: NgxSpinnerService, private router: Router) { }
  dataSource;
  res_apps;
  appid;
  categoryid;
  catname;
  apptopicid;
  qid = 0;
  kbtopics;
  kbquestion = {
    name: "",
    qtypeid: "1",
    kbtopicid: "1",
    qexplaintext: "",
    qtext: "",
    isactive: "1",
    isop: "0",
    isop1: "",
    isop2: "",
    isop3: "",
    isop4: "",
    isop5: "",
    op1text: "",
    op2text: "",
    op3text: "",
    op4text: "",
    op5text: "",
    levelid: "1"
  };
  questionTypes;
  levels;
  ngOnInit() {
    this.qid = +this.route.snapshot.paramMap.get('qid');
    if (this.qid) {
      this.getKbQuestionDetails(this.qid);
    }
    this.getAllKbTopics();
    this.getQuestionTypes();
    this.getLevels();
  }

  getAllKbTopics(): void {
    this.NgxSpinnerService.show();
    this.TopicsService.getAllKbTopics(0).subscribe(
      data => { 
        this.kbtopics = data.kbtopics;
        this.NgxSpinnerService.hide();
      }
    );
  }

  getQuestionTypes(): void {
    this.NgxSpinnerService.show();
    this.QuestionsService.getQuestionTypes().subscribe(
      data => { 
        this.questionTypes = data.questiontypes;
        this.NgxSpinnerService.hide();
       }
    );
  }
  getLevels(): void {
    this.NgxSpinnerService.show();
    this.TopicsService.getLevels().subscribe(
      data => { 
        this.levels = data.levels;
        this.NgxSpinnerService.hide();
       }
    );
  }

  getKbQuestionDetails(qid): void {
    this.NgxSpinnerService.show();
    this.QuestionsService.getKbQuestionDetails(qid).subscribe(
      data => {
        this.kbquestion = data.kbquestion[0];
        if (data.kbquestion[0].qtypeid == 1) {
          data.kbquestion[0].isop = data.kbquestion[0].isop1 ? "1" :
            data.kbquestion[0].isop2 ? "2" :
              data.kbquestion[0].isop3 ? "3" :
                data.kbquestion[0].isop4 ? "4" :
                  data.kbquestion[0].isop5 ? "5" : "0";

          data.kbquestion[0].isop1 = "0";
          data.kbquestion[0].isop2 = "0";
          data.kbquestion[0].isop3 = "0";
          data.kbquestion[0].isop4 = "0";
          data.kbquestion[0].isop5 = "0";
        }
        data.kbquestion[0].qtypeid = data.kbquestion[0].qtypeid.toString();
        data.kbquestion[0].kbtopicid = data.kbquestion[0].kbtopicid.toString();
        data.kbquestion[0].levelid = data.kbquestion[0].levelid.toString();
        this.NgxSpinnerService.hide();
      }
    );
  }

  onAdd(kbquestion,addMore): void {
    this.NgxSpinnerService.show();
    this.QuestionsService.addKbQuestion(kbquestion, this.apptopicid).subscribe(
      data => {
        this.NgxSpinnerService.hide();
        this.res_apps = data;
        if(data.error || addMore){
          alert(data.message);
        }
        if(!addMore){
          this.router.navigate(['/questions']);
        }
      },
    );
  }

  onEdit(kbquestion): void {
    this.NgxSpinnerService.show();
    this.QuestionsService.editKbQuestion(kbquestion, this.apptopicid).subscribe(
      data => {
        this.NgxSpinnerService.hide();
        this.res_apps = data;
        if(data.error){
          alert(data.message);
        }
        this.router.navigate(['/questions']);
      },
    );
  }


}
