import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatSlideToggleModule } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { CategoryService } from '../services/category.service';
import { AppService } from '../services/app.service';
import { TopicsService } from '../services/topics.service';
import { QuestionsService } from '../services/questions.service';
import { kbhtmlService } from '../services/kbhtml.service';
import { FileUploader } from 'ng2-file-upload';
import { SharedService } from '../services/shared.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router} from '@angular/router';

@Component({
  selector: 'app-addeditkbtopics',
  templateUrl: './addeditkbtopics.component.html',
  styleUrls: ['./addeditkbtopics.component.css']
})
export class addEditKbTopicsComponent implements OnInit {

  constructor(private fb: FormBuilder, public dialog: MatDialog, private route: ActivatedRoute, private CategoryService: CategoryService, private AppService: AppService, private TopicsService: TopicsService, private QuestionsService: QuestionsService, private kbhtmlService: kbhtmlService,private SharedService:SharedService,private NgxSpinnerService:NgxSpinnerService, private router: Router) { }
  dataSource;
  res_apps;
  appid;
  categoryid;
  catname;
  kbtopicid;
  kbhtmlid = 0;
  kbcategories;
  levels;
  kbtopic = {
    "name":"",
    "categoryid":"",
    "learningsequence":"",
    "difficultylevel":"1",
    "isactive":1,
    "sortorder":1,
  };
  ngOnInit() {
    this.kbtopicid = +this.route.snapshot.paramMap.get('topicid');
    if (this.kbtopicid) {
      this.getKbTopicDetails(this.kbtopicid);
    }
    this.getKbCategory();
    this.getLevels();
  }

  getKbCategory(): void {
    this.NgxSpinnerService.show();
    this.CategoryService.getKbCategory(0).subscribe(
      data => { 
        this.kbcategories = data.kbcategories;
        this.NgxSpinnerService.hide();
       }
    );
  }

  getLevels(): void {
    this.NgxSpinnerService.show();
    this.TopicsService.getLevels().subscribe(
      data => {
        this.levels = data.levels;
        this.NgxSpinnerService.hide();
      }
    );
  }

  getKbTopicDetails(kbtopicid): void {
    this.NgxSpinnerService.show();
    this.TopicsService.getKbTopicDetails(kbtopicid).subscribe(
      data => {
        this.kbtopic = data.kbtopics[0];
       data.kbtopics[0].categoryid = data.kbtopics[0].categoryid.toString();
      data.kbtopics[0].difficultylevel = data.kbtopics[0].difficultylevel.toString();
        this.NgxSpinnerService.hide();
      }
    );
  }

  onAdd(kbtopic): void {
    this.NgxSpinnerService.show();
    this.TopicsService.addKbTopic(kbtopic).subscribe(
      data => {
        this.NgxSpinnerService.hide();
        this.res_apps = data;
        if(data.error){
          alert(data.message);
        }else{
          this.router.navigate(['/kbtopics']);
        }
      },
    );
  }

  onEdit(kbtopic): void {
    this.NgxSpinnerService.show();
    this.TopicsService.editKbTopic(kbtopic).subscribe(
      data => {
        this.NgxSpinnerService.hide();
        this.res_apps = data;
        if(data.error){
          alert(data.message);
        }else{
          this.router.navigate(['/kbtopics']);
        }
      },
    );
  }


}
