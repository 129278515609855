import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders, HttpResponse, HttpRequest } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import {HttpModule, Http, URLSearchParams, Headers, RequestOptions} from '@angular/http';
import { SharedService } from './shared.service';


@Injectable()
export class CategoryService {

    getCategory(id) {
    return this.http.get<any>(this.SharedService.getUrl+id);
}

addCategory (data): Observable<any> {
  let postdata =  {"appid": data.appid, "title": data.categoryname, "parentid": data.parentid, "iconid": this.SharedService.uploadIconId, "isactive": (data.isactive == true ? 1 : 0),};
  this.SharedService.uploadIconId = 0;
  console.log(postdata);
  return this.http.post<any>(this.SharedService.appcategoryUrl, postdata);
}

editCategory (data): Observable<any> {
  let postdata =  {"appid": data.appid, "title": data.categoryname, "parentid": data.parentid, "isactive": (data.isactive == true ? 1 : 0), "sortorder": data.sortorder};
  if(this.SharedService.uploadIconId){
    postdata['iconid'] = this.SharedService.uploadIconId;
  }
  this.SharedService.uploadIconId = 0;
  console.log(postdata);
  return this.http.put<any>(this.SharedService.appcategoryUrl+'/'+data.categoryid, postdata);
}

sortCategory (data): Observable<any> {
  return this.http.put<any>(this.SharedService.appcategorySortUrl, data);
}

deleteCategory (data): Observable<any> {
  return this.http.delete<any>(this.SharedService.appcategoryUrl+'/'+data.categoryid);
}

  constructor(
    private http: HttpClient,private SharedService:SharedService) { }


    // KB Categories

    getKbCategory(id) {
      return this.http.get<any>(this.SharedService.getUrlKb);
  }

}
