import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FileUploader, FileItem, ParsedResponseHeaders } from 'ng2-file-upload';
import { SharedService } from '../services/shared.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-categorypopup',
  templateUrl: './categorypopup.component.html',
  styleUrls: ['./categorypopup.component.css']
})
export class CategorypopupComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<CategorypopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private SharedService: SharedService, private NgxSpinnerService: NgxSpinnerService) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {  
    
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.uploader = new FileUploader({ url: this.SharedService.iconUploadURL, authToken: `${currentUser.sessiontoken}` });
    this.SharedService.uploadIconId = 0;
    this.uploader.onErrorItem = (item, response, status, headers) => this.onErrorItem(item, response, status, headers);
    this.uploader.onSuccessItem = (item, response, status, headers) => this.onSuccessItem(item, response, status, headers);
  }

  onSuccessItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
    let data = JSON.parse(response); //success server response
    this.SharedService.uploadIconId = data.icon_id;
    this.NgxSpinnerService.hide();
  }

  onErrorItem(item: FileItem, response: string, status: number, headers: ParsedResponseHeaders): any {
    // let error = JSON.parse(response); //error server response
    this.NgxSpinnerService.hide();
  }

  public myUpload(): void {
    this.NgxSpinnerService.show();
    this.uploader.uploadAll();
  }

  public uploader: FileUploader = null;
  public hasBaseDropZoneOver: boolean = false;
  public hasAnotherDropZoneOver: boolean = false;

  public fileOverBase(e: any): void {
    this.hasBaseDropZoneOver = e;
  }

  public fileOverAnother(e: any): void {
    this.hasAnotherDropZoneOver = e;
  }
}