import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders, HttpResponse, HttpRequest } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpModule, Http, URLSearchParams, Headers, RequestOptions } from '@angular/http';
import { SharedService } from './shared.service';


@Injectable()
export class kbhtmlService {

  getKbHtmls(topicid) {
    return this.http.get<any>(this.SharedService.kbHtmlAllUrl + topicid);
  }

  getKbHtmlDetails(kbhtmlid) {
    return this.http.get<any>(this.SharedService.kbHtmlActionUrl + "/" + kbhtmlid);
  }

  getKbQuestions(topicId) {
    return this.http.get<any>(this.SharedService.kbHtmlAllUrl + "/" + topicId);
  }

  addKbHtml(data): Observable<any> {

    let postdata = {};
    let isactive = (data.isactive == true ? 1 : 0);
    postdata = {
      html: data.html,
      kbtopicid: data.kbtopicid,
      title: data.title,
      // "isactive": isactive,
      "sortorder": "1",
    };

    console.log(postdata);
    return this.http.post<any>(this.SharedService.kbHtmlActionUrl, postdata);
  }

  editKbHtml(data): Observable<any> {

    let postdata = {};
    let isactive = (data.isactive == true ? 1 : 0);
    postdata = {
      html: data.html,
      kbhtmlid: data.kbhtmlid,
      kbtopicid: data.kbtopicid,
      title: data.title,
      // "isactive": isactive,
      "sortorder": "1",
    };
    console.log(postdata);
    return this.http.put<any>(this.SharedService.kbHtmlActionUrl + '/' + data.kbhtmlid, postdata);
  }

  deleteKbHtml(data): Observable<any> {
    return this.http.delete<any>(this.SharedService.kbHtmlActionUrl + '/' + data.kbhtmlid);
  }

  sortKbHtml(data): Observable<any> {
    return this.http.put<any>(this.SharedService.kbHtmlSortUrl, data);
  }

  constructor(
    private http: HttpClient,private SharedService:SharedService) { }

}
